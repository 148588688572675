import * as React from "react";
import { Box, Button, Flex, Icon, IconButton, Text } from "@chakra-ui/react";

import { FaArrowLeft } from "react-icons/fa";
import { MdCreditCard } from "react-icons/md";
import {
  ExpressCheckoutElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { trackEvent, trackPixelEvent } from "@utils/tracking";
import { createSuccessCheckoutURL } from "./utils";
import { ErrorView, LoadingView } from "./components";
import { useRootState } from "@components/wrappers/RootWrapper";
import { Product } from "src/products";

export type RequestType =
  | {
      state: "initial";
    }
  | {
      state: "loading";
    }
  | {
      state: "ok";
    }
  | {
      state: "error";
      error: string;
    };

export function CheckoutForm({ product }: { product: Product }) {
  const [paymentType, setPaymentType] = React.useState<"card" | "initial">("initial");
  const [expressCheckoutReady, setExpressCheckoutReady] = React.useState(false);
  const [cardCheckoutReady, setCardCheckoutReady] = React.useState(false);
  const [payment, submitPayment] = usePayment(product);

  const isReady = cardCheckoutReady && expressCheckoutReady;

  return (
    <Box>
      {/* <Flex alignItems={"center"} justifyContent={"space-between"} mb={4}>
        <IconButton
          visibility={paymentType === "card" ? "visible" : "hidden"}
          aria-label="go back"
          icon={<Icon as={FaArrowLeft} />}
          onClick={() => setPaymentType("initial")}
          variant={"text"}
          width={"40px"}
        />

        <Text flex={1} textAlign={"center"} fontWeight={"bold"} color="blue.700">
          Total due today: ${product.shopify.price.unit_amount / 100}
        </Text>
        <Box width={"40px"}></Box>
      </Flex> */}

      {payment.state === "error" && <ErrorView text={payment.error} />}

      {!isReady && <LoadingView text="Loading secure payment form..." />}

      <Box display={isReady ? "block" : "none"}>
        <Box display={paymentType === "initial" ? "block" : "none"}>
          <ExpressCheckoutElement
            options={{
              layout: { maxColumns: 1, maxRows: 5 },
            }}
            onReady={() => setExpressCheckoutReady(true)}
            onConfirm={() => {
              submitPayment("express");
            }}
          />

          <Button
            size="lg"
            colorScheme="blue"
            width={"full"}
            mt={4}
            leftIcon={<Icon as={MdCreditCard} />}
            onClick={() => setPaymentType("card")}
          >
            Credit or debit card
          </Button>
        </Box>

        <Flex
          display={paymentType === "card" ? "flex" : "none"}
          direction={"column"}
          gap={4}
          alignItems={"start"}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitPayment("card");
            }}
            style={{ width: "100%" }}
          >
            <PaymentElement
              onReady={() => setCardCheckoutReady(true)}
              options={{
                wallets: { applePay: "never", googlePay: "never" },
                layout: "tabs",
                terms: { card: "never" },
              }}
            />

            <Button
              type="submit"
              colorScheme="blue"
              width={"full"}
              size="lg"
              mt={4}
              isLoading={payment.state === "loading"}
            >
              Start 7-day trial
            </Button>
          </form>
        </Flex>
      </Box>
    </Box>
  );
}

function usePayment(product: Product): [RequestType, (type: "express" | "card") => Promise<void>] {
  const productRef = React.useRef(product);
  const stripe = useStripe();
  const elements = useElements();

  const [request, setRequest] = React.useState<RequestType>({
    state: "initial",
  });

  async function submit(type: "express" | "card") {
    if (!stripe || !elements) {
      console.warn("data missing for subscription setup");
      return;
    }

    if (request.state !== "initial") {
      return;
    }

    setRequest({ state: "loading" });

    const redirectUrl = createSuccessCheckoutURL(
      type,
      productRef.current.shopify.price.unit_amount,
      productRef.current.shopify.price.currency,
      productRef.current.shopify.price.id
    );

    trackPixelEvent("AddPaymentInfo");

    trackEvent({
      name: "add-payment-info",
      properties: {},
    });

    try {
      elements.update({
        amount: productRef.current.shopify.price.unit_amount,
        currency: productRef.current.shopify.price.currency,
      });

      // Trigger form validation and wallet collection
      const elementSubmission = await elements.submit();
      if (elementSubmission.error) {
        setRequest({
          state: "error",
          error: elementSubmission.error.message ?? "Element submission failed",
        });

        return;
      }

      // const subscription = await eden("/payments/createSubscription", {
      //   method: "POST",
      //   body: {
      //     userID: userProfile.id,
      //     priceID: trialPricingPlan.recurring.priceID,
      //     oneTimeFeePriceID: selectedPricingPlan,
      //   },
      // });

      // if (subscription.error) {
      //   setRequest({
      //     state: "error",
      //     error: subscription.error.message,
      //   });

      //   return;
      // }

      // if (!subscription.data) {
      //   setRequest({
      //     state: "error",
      //     error: "missing payment intent data",
      //   });

      //   return;
      // }

      // // Empty client secret means that overall price of the cart is very close to 0.
      // // In that case subscription becomes automatically activated.
      // // GOOD FOR TESTING!
      // if (!subscription.data.client_secret) {
      //   console.log("no client secret, payment has been paid");

      //   return;
      // }

      // const confirmation = await stripe.confirmPayment({
      //   elements,
      //   clientSecret: subscription.data.client_secret,
      //   confirmParams: {
      //     return_url: redirectUrl.toString(),
      //   },
      // });

      // if (confirmation.error) {
      //   console.error(confirmation.error);
      //   setRequest({
      //     state: "error",
      //     error: confirmation.error.message ?? "Your payment has been declined.",
      //   });

      //   return;
      // }

      setRequest({ state: "ok" });
    } catch (err) {
      console.error(err);
      setRequest({ state: "error", error: String(err) });
    }
  }

  return [request, submit];
}
