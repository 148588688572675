import * as React from "react";
import { merge } from "lodash";
import "../../styles/global.css";
import { GlobalHead } from "./head";
import { LocationProvider, useLocation } from "@gatsbyjs/reach-router";
import * as Sentry from "@sentry/gatsby";
import posthog from "posthog-js";
import { Product, products } from "src/products";
import { navigate } from "gatsby";
import { useSiteMetadata } from "@hooks/useSiteMetadata";
import { AvatarAttributes, UserAttributes } from "@data/types";
import { sessionCache } from "src/sessionCache";

type RootState = {
  products: Product[];
  selectedProductID: string;
  setSelectedProductID: (id: string) => void;
  offerTime: number;
  setOfferTime: (time: number) => void;
  userAttributes?: UserAttributes;
  updateUserAttributes: (userAttributes: Partial<UserAttributes>) => void;
  avatarAttributes?: AvatarAttributes;
  updateAvatarAttributes: (avatarAttributes: Partial<AvatarAttributes>) => void;
};

const RootStateContext = React.createContext<RootState | null>(null);

export function useRootState(): RootState {
  const ctx = React.useContext(RootStateContext);
  if (!ctx) {
    throw new Error("useRootState must be used within RootStateContext");
  }

  return ctx;
}

/** Wraps every page but is not re-mounted when chaning pages */
export function RootWrapper(props: React.PropsWithChildren) {
  const [userAttributes, setUserAttributes] = React.useState<UserAttributes | undefined>();
  const [avatarAttributes, setAvatarAttributes] = React.useState<AvatarAttributes | undefined>();
  const [offerTime, setOfferTime] = React.useState<number>(899000);
  const [selectedProductID, setSelectedProductID] = React.useState<string>(
    products[0].shopify.product.id
  );

  React.useEffect(() => {
    // Additional data passed to Sentry that ensures easier issue debugging
    const posthogID = posthog.get_distinct_id();
    if (posthogID) {
      Sentry.setUser({
        posthogID: posthogID,
      });
    }
  }, []);

  const value = React.useMemo<RootState>(() => {
    return {
      products,
      offerTime,
      setOfferTime,
      selectedProductID,
      setSelectedProductID,
      userAttributes,
      updateUserAttributes: (value) => {
        setUserAttributes((it) => {
          const next = { ...merge(it, value) };

          sessionCache.setUserAttributes(next);

          return next;
        });
      },
      avatarAttributes,
      updateAvatarAttributes: (value) => {
        setAvatarAttributes((it) => {
          const next = { ...merge(it, value) };

          sessionCache.setAvatarAttributes(next);

          return next;
        });
      },
    };
  }, [offerTime, userAttributes, selectedProductID, avatarAttributes]);

  return (
    <RootStateContext.Provider value={value}>
      <LocationProvider>
        <GlobalHead />
        {/* <RedirectOnMissingProfile /> */}

        {props.children}
      </LocationProvider>
    </RootStateContext.Provider>
  );
}

function RedirectOnMissingProfile() {
  const location = useLocation();
  const { userAttributes } = useRootState();
  const meta = useSiteMetadata();

  React.useEffect(() => {
    // Regular expression to match "/funnel-1/" followed by at least one character
    const isInFunnelUrl = /\/funnel-1\/.+/.test(location.pathname);
    if (isInFunnelUrl && !userAttributes) {
      console.warn(`[${meta.brandName}] redirecting: no user profile was found`);
      navigate("/funnel-1");
    }
  }, []);

  return null;
}
