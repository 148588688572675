import { AvatarAttributes, UserAttributes } from "@data/types";

type UserSession = {
  userAttributes?: UserAttributes;
  avatarAttributes?: AvatarAttributes;
};

const KEY = "lovelica-user-session";
const DEFAULT_SESSION: UserSession = {};

function setSession(input: Partial<UserSession>) {
  const session = getSession() ?? DEFAULT_SESSION;
  sessionStorage.setItem(KEY, JSON.stringify({ ...session, ...input }));
}

function getSession(): UserSession {
  const item = sessionStorage.getItem(KEY);
  if (!item) {
    return DEFAULT_SESSION;
  }

  try {
    return JSON.parse(item);
  } catch (err) {
    console.error("Failed to parse user session data from session storage");
    return DEFAULT_SESSION;
  }
}

function createNewCache(): void {
  const session = getSession();
  if (session) {
    return;
  }

  setSession(DEFAULT_SESSION);
}

export const sessionCache = {
  createNewCache,
  getUserAttributes: () => getSession().userAttributes,
  getAvatarAttributes: () => getSession().avatarAttributes,
  setUserAttributes: (userAttributes: UserAttributes) => setSession({ userAttributes }),
  setAvatarAttributes: (avatarAttributes: AvatarAttributes) => setSession({ avatarAttributes }),
};
