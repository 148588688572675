import * as React from "react";
import { Box, Container, Slide } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { siteConfig } from "src/conf";
import { useLocation } from "@gatsbyjs/reach-router";
import { useRootState } from "@components/wrappers/RootWrapper";

import "../../styles/global.css";
import { Product } from "src/products";

const stripe = loadStripe(siteConfig.stripePublicKey);

export function CheckoutDrawer() {
  const location = useLocation();
  const { products } = useRootState();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [plan, setPlan] = React.useState<Product | null>(null);

  React.useEffect(() => {
    if (location.pathname.includes("/checkout")) {
      findPlan();
      setIsDrawerOpen(true);
    }

    return () => {
      setIsDrawerOpen(false);
    };
  }, [location]);

  function findPlan() {
    const params = new URLSearchParams(location.search);
    const planIdParam = params.get("plan-id");

    if (planIdParam) {
      const plan = products.find((it) => it.shopify.product.id === planIdParam);
      if (plan) {
        setPlan(plan);
      }
    }
  }

  if (!plan) {
    return null;
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: "subscription",
        amount: plan.shopify.price.unit_amount,
        currency: plan.shopify.price.currency,
        appearance: {
          theme: "stripe",
          variables: {
            tabLogoColor: "green",
            tabLogoSelectedColor: "red",
          },
        },
      }}
    >
      <Slide
        direction="bottom"
        in={isDrawerOpen}
        style={{ zIndex: 10, backgroundColor: "white", boxShadow: "0px 10px 20px 0px black" }}
      >
        <Container py={5}>
          <CheckoutForm product={plan} />
        </Container>
      </Slide>
    </Elements>
  );
}
